.classLabel {
  margin-top: 20px;
  font-weight: bold;
  color: #000;
}

.classDesc {
  margin-top: 20px;
  color: #000;
}

.column {
  float: left;
  width: 100px;
  padding: 0 10px;
}

.customcolumn {
  float: left;
  width: 130px;
  padding: 0 10px;
  text-align: right;
}

.columnFluid {
  float: left;
}

.diesel {
  color: #e2b40d;
}

.gas91 {
  color: #10ac84;
}

.gas95 {
  color: #ee3b34;
}

.gas97 {
  color: #2e4491;
}

.unit {
  width: 40px;
  background-color: #edeff6;
  border: 1px solid #b1bada;
  border-left: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 40px;
  align-items: center;
  text-align: center;
  .text {
    margin: auto;
    width: 50%;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    // color: #b1bada;
    color: #000;
    // border: 1px solid cornflowerblue;
  }
}

.textfield {
  height: 40px;
  width: 150px !important;
  border: 1px solid #b1bada;
  text-align: center;
  // pointer-events: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
