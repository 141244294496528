.header {
  margin-top: 40px;
  padding-left: 5px;
  font-weight: bold;
  color: #000;
  font-size: 16px;
}

.classLabel {
  margin-top: 20px;
  font-weight: bold;
  color: #000;
}

.content {
  margin-top: 20px;
  font-weight: normal;
  color: #000;
}

.fields {
  width: 100%;
  div:first-child {
    min-width: 20%;
    color: #000;
    font-weight: 600;
    padding-left: 2.5px;
  }
}

.tableView {
  thead > tr > th {
    text-align: left;
  }
  .remove {
    align-self: center;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
    img {
      margin-top: 20px;
      width: 24px;
    }
  }
}

.addOrEditTd {
  padding-right: 16px;
}
