.table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  > thead {
    background-color: #e5e5e5;

    > tr:first-child th:first-child {
      border-top-left-radius: 10px;
      border-left: 1px solid var(--color-ash);
    }

    > tr:first-child th:last-child {
      border-top-right-radius: 10px;
      border-right: 1px solid var(--color-ash);
    }

    > tr > th {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-top: 10px;
      font-size: 14px;
      font-weight: 600;
      vertical-align: text-top;
    }
  }

  > tbody {
    background-color: #ffffff;
    > tr:hover {
      background: var(--color-baby-blue);
    }

    // > tr:nth-of-type(odd) {
    //   background-color: #f4f5f7;
    // }

    // >tr:last-child{
    //   background-color: #eae8ff;
    //   color: #5349b7 !important;
    //   font-weight: 600;
    // }

    > tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }
    > tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
    > tr:first-child > td {
      border-top: 1px solid var(--color-ash);
    }
    > tr > td:first-child {
      border-left: 1px solid var(--color-ash);
    }
    > tr > td {
      border-bottom: 1px solid var(--color-ash);
      padding: 10px 20px;
      vertical-align: text-top;
    }
    > tr > td:last-child {
      border-right: 1px solid var(--color-ash);
    }
  }
}

.headerText {
  color: black;
}

.content {
  color: black !important;
}

.container {
  overflow-x: auto;
}

.lastColumnWithColumnPicker {
  display: flex;
  justify-content: space-between;
}

.modifyColumn {
  margin-left: 10px;

  .modifyColumnIcon {
    font-size: 15px;
    transition: color 1s;
    &:hover {
      color: var(--color-indigo);
    }
  }
}

.modifyColumnContent {
  max-height: 400px !important;
}
