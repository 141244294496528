.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContent {
  width: 235px;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.search {
  width: 600px;
  margin-left: 10px;
}

.downloadButton {
  div {
    column-gap: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
