.container {
  background-color: #fefefe;
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow-y: auto;
}

.title {
  margin-top: 0px;
  line-height: 26px;
}

.remember {
  display: flex;
  align-items: center;
  margin: 5px 0px 10px 0px;
}

.header {
  height: 70px;
  padding: 14px 28px;
  .logoImage {
    height: 39px;
  }
}

.loginEmbelishment1{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.loginEmbelishment2{
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}

.content {
  height: calc(100% - 120px);
  min-height: 500px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  justify-content: center;
}

.contentContainer {
  background-color: #fefefe;
  z-index: 2;
  border-radius: 40px;
  box-shadow: 0 5px 5px 5px rgb(0 0 0 / 10%);
  width: 1000px;
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 175px;
  padding-bottom: 0px;

  .image {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    text-align: center;
    align-items: center;
    width: 330px;
    height: 640px;
    margin-right: 211px;
    margin-bottom: 0px;
    
  }
  .contentImage {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    width: 100%;
    height: 100%;
  }
  .login {
    // background-color: goldenrod;
    width: 35%;
  }
}

.loginButton {
  width: 100%;
}

