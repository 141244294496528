.container {
  text-align: center;
  width: 500px;
}
.image {
  width: 200px;
}

.title {
  margin-bottom: 5px;
  color: #000;
}

.content {
  max-width: 470px;
  margin: auto;
  color: #000;
}
