.container {
  width: 100%;
}

.dropdown {
  width: 100%;
  height: 38px;

  :global {
    .MuiSelect-root.MuiSelect-select:focus {
      background: none;
    }

    fieldset {
      border-color: var(--color-ash-dark);
    }

    .PrivateNotchedOutline-legendLabelled-3 {
      display: none !important;
    }
  }
  .placeholder {
    color: var(--color-cement);
  }
}

.simple {
  height: unset !important;
  :global {
    .MuiSelect-root.MuiSelect-select:focus {
      background: none;
    }

    .MuiSelect-root,
    .MuiSvgIcon-root {
      color: var(--color-blue-gray);
      font-weight: 400 !important;
    }

    .MuiInputBase-root {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      height: 35px !important;
      border-radius: 2px;
    }
  }
}

.disabled {
  background: var(--color-concrete);
  :global {
    .MuiOutlinedInput-notchedOutline {
      border: 0px solid !important;
    }
  }
}
