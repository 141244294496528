.container {
  margin-top: 20px;
  padding: 5px;
}

.subContainer {
  padding: 5px;
}

.column {
  float: left;
  margin-right: 50px;
}

.classLabel {
  margin-top: 20px;
  font-weight: bold;
  color: #000;
}

.diesel {
  color: #e2b40d;
}

.gas91 {
  color: #10ac84;
}

.gas95 {
  color: #ee3b34;
}

.gas97 {
  color: #2e4491;
}

.content {
  color: black;
}

.label {
  color: black;
  font-weight: bold;
}

.positive {
  color: #10ac84 !important;
}

.negative {
  color: #ee3b34 !important;
}

.spacer10 {
  margin: 10px;
}

.spacer20 {
  margin: 20px;
}

.spacer50 {
  margin: 50px;
}

.header {
  font-weight: bold;
  color: black;
  font-size: 16px;
  margin-top: 20px;
}

.noSearchResultRender {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  > div {
    margin: auto;
    text-align: center;
  }
}

.tableContainer {
  background-color: #f7f8fa;
  border: 1px solid var(--color-ash);
  border-radius: 10px;

  .fuelSalesTable {
    padding: 20px;
  }

  .table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    > thead {
      background-color: #e5e5e5;
      border-top-left-radius: 10px;
      float:left > tr:first-child th:first-child {
        // border-top-left-radius: 10px;
        border-left: 1px solid var(--color-ash);
      }

      > tr:first-child th:last-child {
        // border-top-right-radius: 10px;
        border-right: 1px solid var(--color-ash);
      }

      > tr > th {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
        vertical-align: text-top;
        text-align: left;
      }
    }

    > tbody {
      background-color: #ffffff;
      > tr:hover {
        background: var(--color-baby-blue);
      }

      // > tr:nth-of-type(odd) {
      //   background-color: #f4f5f7;
      // }

      > tr:last-child {
        // background-color: #f7f8fa;
        color: black !important;
        font-weight: 600;
      }

      > tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      > tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
      > tr:first-child > td {
        border-top: 1px solid var(--color-ash);
      }
      > tr > td:first-child {
        border-left: 1px solid var(--color-ash);
      }
      > tr > td {
        border-bottom: 1px solid var(--color-ash);
        padding: 10px 20px;
      }
      > tr > td:last-child {
        border-right: 1px solid var(--color-ash);
      }
    }
  }

  .headerText {
    color: black;
  }

  .content {
    color: black;
  }

  .container {
    overflow-x: auto;
  }

  .lastColumnWithColumnPicker {
    display: flex;
    justify-content: space-between;
  }

  .modifyColumn {
    margin-left: 10px;

    .modifyColumnIcon {
      font-size: 15px;
      transition: color 1s;
      &:hover {
        color: var(--color-indigo);
      }
    }
  }

  .modifyColumnContent {
    max-height: 400px !important;
  }
}

.tableHeader {
  min-width: 100%;
  height: 60px;
  background-color: #f0effa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid var(--color-ash);
  .label {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
}

.tableRow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--color-ash);
  background-color: #ffffff;
  .label {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #000 !important;
  }
}

.editSalesContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2em;
  .beginningInventory {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    .checkBox {
      display: flex;
      align-items: center;
    }
    .dipstick {
      margin-bottom: 20px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .columnFluid {
      flex: 1;
      margin: 10px;
    }
  }
  .endingInventory {
    display: flex;
    flex-direction: column;
    .checkBox {
      display: flex;
      align-items: center;
    }
    .dipstick {
      margin-bottom: 20px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .columnFluid {
      flex: 1;
      margin: 10px;
    }
  }
}
