.status {
  padding: 10px 15px 10px 15px;
}

.td {
  color: black;
}

.sync {
  font-weight: 600;
  .percentage {
    color: var(--color-cement);
  }
  .content {
    padding-left: 15px;
    color: black;
  }

  .pending {
    color: var(--color-cement);
  }

  .ongoing {
    color: var(--color-cheddar);
  }

  .done {
    color: var(--color-grass);
  }
}
