.button {
  text-transform: none;
  border-radius: 4px;
  min-height: 36px;
  font-weight: 400;
  padding: 10px 20px;
  box-shadow: none;
  height: 36px;
}

.primary {
  color: var(--color-white);
  background: #5349b7;
}

.circular {
  background: none;
  width: 20px !important;
  height: 20px !important;

  &.primary {
    color: var(--color-white);
  }
}

.disabled {
  background: var(--color-ash);
  color: var(--color-white) !important;
}

.link {
  background: unset;
  text-decoration: underline;
  text-decoration-color: var(--color-purple);
  padding: 0;
  color: var(--color-purple);
  font-weight: 500;
}

.link:hover,
.link:active {
  box-shadow: unset;
  background: unset;
  text-decoration: inherit;
}

.outline {
  font-weight: 500;
  background: unset;
  color: var(--color-purple);
  border: 1.5px solid;
  padding: 7px 20px;
}

.outline:hover {
  background-color: var(--color-baby-blue);
  box-shadow: unset;
}

.exportbutton {
  background: var(--color-pale-purple);
  transition: background 0.5s;
  span {
    color: var(--color-purple);
    font-weight: 500;
    align-items: center;
    display: flex;
  }
  &:hover,
  &.active {
    background: var(--color-purple);
    span {
      color: var(--color-white);
    }
  }
}
