.classLabel{
    margin-top: 20px;
    font-weight: bold;
    color: #000;
} 

.classDesc{
    margin-top: 20px;
    color: #000;
}

.column {
  float: left;
  width: 100px;
  padding: 0 10px;
}

.diesel {
  color: #e2b40d;
}

.gas91 {
  color: #10ac84;
}

.gas95 {
  color: #ee3b34;
}

.gas97 {
  color: #2e4491;
}