.alignToBottom {
  vertical-align: bottom;
  color: gray;
}
.alignToTop {
  vertical-align: top;
}
.customHeaderText {
  color: black;
}
.subtitle {
  display: block;
  color: gray;
}