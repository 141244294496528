@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

// Font Custom Icon

@font-face {
  font-family: "icomoon";
  src: url("../images/fonts/icomoon.eot");
  src: url("../images/fonts/icomoon.eot") format("embedded-opentype"),
    // url('../images/fonts/icomoon.ttf') format('truetype'),
    url("../images/fonts/icomoon.woff") format("woff"),
    url("../images/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fleet-activity:before {
  content: "\e900";
}
.icon-dashboard:before {
  content: "\e901";
}
.icon-fuel:before {
  content: "\e902";
}
.icon-cash-flow:before {
  content: "\e903";
}
.icon-pricing:before {
  content: "\e904";
}
.icon-pencil:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90e";
}
.icon-grid:before {
  content: "\e908";
}

.icon-filter:before {
  content: "\e906";
}

.icon-download:before {
  content: "\e907";
}

.icon-campaigns:before {
  content: "\e90f";
}

.icon-sync:before {
  content: "\ea2e";
}

.icon-list:before {
  content: "\e922";
}

.icon-map:before {
  content: "\e923";
}

.icon-chevron-right:before {
  content: "\e924";
}

.icon-chevron-left:before {
  content: "\e925";
}

.icon-access:before {
  content: "\e910";
}

.icon-competitor:before {
  content: "\e912";
}

.icon-gas:before {
  content: "\e90a";
}
