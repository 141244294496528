.container {
  margin-top: 20px;
  padding: 5px;
}

.spacer10 {
  margin: 10px;
}

.spacer20 {
  margin: 20px;
}

.content {
  color: black !important;
}

.positive {
  color: #10ac84;
}

.negative {
  color: #ee3b34;
}

.label {
  color: black !important;
  font-weight: bold;
}

.spacer50 {
  margin: 50px;
}

.header {
  font-weight: bold;
  color: #000;
  font-size: 16px;
}

.noSearchResultRender {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  > div {
    margin: auto;
    text-align: center;
  }
}

.netCashTable {
  padding: 30px;
  background-color: #f7f8fa;
  border: 1px solid var(--color-ash);
  width: 550px;
  .table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    > thead {
      background-color: #e5e5e5;

      > tr:first-child th:first-child {
        border-top-left-radius: 10px;
        border-left: 1px solid var(--color-ash);
      }

      > tr:first-child th:last-child {
        border-top-right-radius: 10px;
        border-right: 1px solid var(--color-ash);
      }

      > tr > th {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
        vertical-align: text-top;
      }
    }

    > tbody {
      background-color: #ffffff;
      > tr:hover {
        background: var(--color-baby-blue);
      }

      > tr:first-child td:first-child {
        border-top-left-radius: 10px;
      }
      > tr:first-child td:last-child {
        border-top-right-radius: 10px;
      }

      > tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      > tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
      > tr:first-child > td {
        border-top: 1px solid var(--color-ash);
      }
      > tr > td:first-child {
        border-left: 1px solid var(--color-ash);
      }
      > tr > td {
        border-bottom: 1px solid var(--color-ash);
        padding: 10px 20px;
      }
      > tr > td:last-child {
        border-right: 1px solid var(--color-ash);
      }
    }
  }

  .headerText {
    color: black;
  }

  .content {
    color: black !important;
  }

  .container {
    overflow-x: auto;
  }

  .lastColumnWithColumnPicker {
    display: flex;
    justify-content: space-between;
  }

  .modifyColumn {
    margin-left: 10px;

    .modifyColumnIcon {
      font-size: 15px;
      transition: color 1s;
      &:hover {
        color: var(--color-indigo);
      }
    }
  }

  .modifyColumnContent {
    max-height: 400px !important;
  }
}

.tableContainer {
  padding: 10px;
  background-color: #f7f8fa;
  border: 1px solid var(--color-ash);

  .table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid var(--color-ash);
    border-radius: 10px;

    > thead {
      background-color: #f4f5f7;
      text-align: left;

      > tr:first-child th:first-child {
        border-top-left-radius: 10px;
        border-left: 1px solid var(--color-ash);
      }

      > tr:first-child th:last-child {
        border-top-right-radius: 10px;
        border-right: 1px solid var(--color-ash);
      }

      > tr > th {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
        vertical-align: text-top;
      }
    }

    > tbody {
      background-color: #ffffff;
      > tr:hover {
        background: var(--color-baby-blue);
      }

      > tr:nth-of-type(even) {
        background-color: #f4f5f7;
      }

      > tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      > tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
      > tr:first-child > td {
        border-top: 1px solid var(--color-ash);
      }
      > tr > td:first-child {
        border-left: 1px solid var(--color-ash);
      }
      > tr > td {
        border-bottom: 1px solid var(--color-ash);
        padding: 10px 20px;
      }
      > tr > td:last-child {
        border-right: 1px solid var(--color-ash);
      }
    }
  }

  .headerText {
    color: black;
  }

  .content {
    color: black !important;
  }

  .container {
    overflow-x: auto;
  }

  .lastColumnWithColumnPicker {
    display: flex;
    justify-content: space-between;
  }

  .modifyColumn {
    margin-left: 10px;

    .modifyColumnIcon {
      font-size: 15px;
      transition: color 1s;
      &:hover {
        color: var(--color-indigo);
      }
    }
  }

  .modifyColumnContent {
    max-height: 400px !important;
  }
}

.tableView {
  padding-top: 35px;
  thead > tr > th {
    text-align: left;
  }
  .remove {
    align-self: center;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
    img {
      margin-top: 24px;
    }
  }
}

.coinAmountInput {
  width: 200px;
}
