.column {
  float: left;
}

.buttonDec {
  width: 40px;
  background-color: #eeeff6;
  border: 1px solid #b5bfe0;
  border-right: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  height: 40px;
  align-items: center;
  text-align: center;
  .text {
    margin: auto;
    margin-top: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  .text.disabled {
    color: #b1bada;
  }
}


.buttonInc {
  width: 40px;
  background-color: #eeeff6;;
  border: 1px solid #b5bfe0;
  border-left: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0px;
  height: 40px;
  align-items: center;
  text-align: center;
  .text {
    margin: auto;
    margin-top: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    // border: 1px solid cornflowerblue;
  }
  .text.disabled {
    color: #b1bada;
  }
}

.textfield {
  height: 40px;
  width: 80px !important;
  border: 1px solid #b1bada;
  text-align: center;
  pointer-events: none;
}
