.pagination {
  margin-top: 20px;
}

.noSearchResultRender {
  display: flex;
  align-items: center;
  margin-top: 40px;
  > div {
    margin: auto;
    text-align: center;
  }
}

.hidePagination {
  display: none;
}

.tableContainer {
  margin: 20px;
  border: 1px solid var(--color-ash);
  border-radius: 10px;
}

.tableContainerColored {
  background-color: #e5e5e5;
  margin: 20px;
  border: 1px solid var(--color-ash);
  border-radius: 10px;
}

.tableHeader {
  min-width: 100%;
  height: 60px;
  background-color: #f0effa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid var(--color-ash);
  .label {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
}

.spacer30 {
  margin: 30px;
}

.searchResult {
  color: var(--color-night);
}

.tableRow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--color-ash);
  background-color: #ffffff;
  .label {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #000 !important;
  }
}
